<template>
  <div class="documentatBlock">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="TitleBocks">
          <el-input v-model="form.name" placeholder="Template name"></el-input>
        </div>
      </el-col>
      <el-col :span="6">
        <div class>
          <el-select @change="change" clearable v-model="template" placeholder="Select">
            <el-option
              v-for="(item, index) in list"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :sm="12">
        <div class>
          <el-button size="medium" type="success" @click="print">{{ $t('message.print') }}</el-button>
          <el-button
            size="medium"
            :loading="update_loading"
            type="primary"
            @click="updateData"
          >{{ $t('message.update') }}</el-button>
          <el-button size="medium" :loading="save_loading" type="primary" @click="saveData">{{ $t('message.save') }}</el-button>
          <el-button
            size="medium"
            :loading="remove_loading"
            type="danger"
            @click="removeData"
          >{{ $t('message.delete') }}</el-button>
        </div>
      </el-col>
    </el-row>
    <div class="documents_block">
      <el-row :gutter="24">
        <el-col :sm="12">
          <div style="padding: 20px">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item title="Header" name="1">
                <el-card shadow="always">
                  <ckeditor
                    :editor="editor"
                    v-model="form.header_template"
                    @ready="onReady"
                    :config="editorConfig"
                  ></ckeditor>
                </el-card>
              </el-collapse-item>
              <el-collapse-item title="Content" name="2">
                <el-card shadow="always">
                  <ckeditor
                    :editor="editor"
                    v-model="form.content"
                    @ready="onReady"
                    :config="editorConfig"
                  ></ckeditor>
                </el-card>
              </el-collapse-item>
              <el-collapse-item title="Footer" name="3">
                <el-card shadow="always">
                  <ckeditor
                    :editor="editor"
                    v-model="form.footer_template"
                    @ready="onReady"
                    :config="editorConfig"
                  ></ckeditor>
                </el-card>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-col>
        <el-col :sm="12">
          <el-card shadow="always">
            <div class="content-table" v-html="form.header_template"></div>
            <br />
            <div class="content-table" v-html="form.content"></div>
            <br />
            <div class="content-table" v-html="form.footer_template"></div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <!-- <el-pagination
            class="mt-3 mypagination"
            background
            layout="prev, pager, next"
            :total="1000">
    </el-pagination>-->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "createForm",
  data() {
    return {
      list: [],
      activeName: "2",
      serchoInput: "",
      save_loading: false,
      update_loading: false,
      remove_loading: false,
      template: "",
      form: {
        name: "",
        header_template: "",
        content: "",
        header_template: ""
      }
    };
  },
  watch: {},
  created() {
    this.fetchList();
  },
  methods: {
    ...mapActions({
      updateList: "formTemplate/index",
      save: "formTemplate/store",
      update: "formTemplate/update",
      remove: "formTemplate/destroy"
    }),
    fetchList() {
      this.updateList({})
        .then(res => {
          this.list = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    saveData() {
      if (this.form.name != "" && this.form.content != "") {
        this.save_loading = true;
        this.save(this.form)
          .then(res => {
            this.save_loading = false;
            this.fetchList();
            this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
            this.loadModel();
          })
          .catch(err => {
            this.save_loading = false;
            console.log(err);
          });
      } else {
        this.$alert({ code: 402, message: "Empty data", success: false });
      }
    },
    updateData() {
      if (this.form.id) {
        this.update_loading = true;
        this.update(this.form)
          .then(res => {
            this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
            this.update_loading = false;
            this.fetchList();
            this.loadModel();
          })
          .catch(err => {
            this.update_loading = false;
            console.log(err);
          });
      } else {
        this.$alert({
          code: 402,
          message: "Place change template form",
          success: false
        });
      }
    },
    removeData() {
      if (this.form.id) {
        this.remove_loading = true;
        this.remove(this.form.id)
          .then(res => {
            this.remove_loading = false;
            this.fetchList();
            this.loadModel();
            this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          })
          .catch(err => {
            this.remove_loading = false;
            console.log(err);
          });
      } else {
        this.$alert({
          code: 402,
          message: "Place change template form",
          success: false
        });
      }
    },
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    print() {
      if (!this.form.id) {
        this.$alert({
          code: 402,
          message: "Place change template form",
          success: false
        });
        return;
      }
      let DATA =
        this.form.header_template +
        "<br>" +
        this.form.content +
        "<br>" +
        this.form.footer_template;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(DATA);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    change(id) {
      let item = this.list.filter(item => {
        return item.id == id;
      });
      if (item.length) {
        this.form.id = item[0].id;
        this.form.name = item[0].name;
        this.form.header_template = item[0].header_template;
        this.form.content = item[0].content;
        this.form.footer_template = item[0].footer_template;
      } else {
        this.form = {};
      }
    },
    loadModel() {
      this.form = {};
      this.template = "";
    }
  }
};
</script>
<style lang="scss">
.content-table {
  line-height: 2px;
}
.content-table ul,
.content-table p {
  line-height: 24px;
}
.content-table table {
  width: 100%;
}
.el-card.is-always-shadow {
  min-height: 700px;
}
.content-table tr,
.content-table td {
  border: 1px solid black;
}
.documentatBlock .max_buttom:hover {
  text-decoration: none;
}
.TitleBocks {
  font-size: 18px;
  line-height: 20px;
  span {
    font-size: 14px;
    color: #8e8e8e;
  }
}
.documents_block {
  min-height: 750px;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 4px;
  overflow: hidden;

  // div{padding: 15px 20px;}
  .document_title {
    background-color: #cecece6b;
  }
  .item-documents {
    border-top: 1px solid #f2f2f2;
    transition: background-color 0.5s;
    cursor: pointer;
    &:hover {
      background-color: #cecece6b;
    }
  }
}
</style>